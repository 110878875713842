import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { BankAccount, Organization } from 'store/api/api.types';
import { IconName } from 'components/core/Icon/Icon.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import EmptyField from 'components/core/Form/EmptyField/EmptyField';
import Icon from 'components/core/Icon/Icon';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import modalService from 'services/modalService';

import styles from './ModalBankAccountDetails.module.scss';

import { BankDetailsComponentId } from '../config/bankDetails.types';
import { getBankDetailsWithLabels } from '../config/bankDetails';
import ModalBankAccountDelete from '../ModalBankAccountDelete/ModalBankAccountDelete';

const ModalBankAccountDetails: FC<{
  bankAccount: BankAccount;
  organizationId: Organization['id'];
}> = ({ bankAccount, organizationId }) => {
  const { isUserAllowedTo } = useCurrentUser();

  const canDeleteBankAccount = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.DeleteBankAccount),
    [organizationId, isUserAllowedTo],
  );

  const bankDetails = useMemo(() => {
    return getBankDetailsWithLabels(bankAccount.attributes).filter(component => component.value);
  }, [bankAccount.attributes]);

  return (
    <>
      <ModalContent variant='noVerticalPadding'>
        {bankDetails.map(({ id, label, value }) => {
          if (id === BankDetailsComponentId.currency) {
            return (
              <EmptyField key={id} label={label}>
                <div className={styles.currency}>
                  <Icon iconName={`assetType${value}` as IconName} size={1.8} />
                  <span>{value}</span>
                </div>
              </EmptyField>
            );
          }

          if (
            id === BankDetailsComponentId.accountType &&
            bankAccount.attributes.country === 'MEX' &&
            bankAccount.attributes.currency === 'MXN'
          ) {
            return;
          }

          return (
            <EmptyField key={id} label={label}>
              {value}
            </EmptyField>
          );
        })}
      </ModalContent>
      <ModalContent className={styles.bottomButtons} variant='footerButtonsWithoutTopBorder'>
        {canDeleteBankAccount && (
          <Button
            label='Delete Bank'
            onClick={() =>
              modalService.openModal(
                {
                  title: 'Delete?',
                },
                <ModalBankAccountDelete
                  bankAccount={bankAccount}
                  organizationId={organizationId}
                />,
              )
            }
            variant='destructiveSecondary'
          />
        )}
        <Button
          label='Close'
          onClick={() => modalService.closeCurrentModal()}
          variant='secondary'
        />
      </ModalContent>
    </>
  );
};

export default ModalBankAccountDetails;
