import colors from './_colorsExportToJS.module.scss';

export const {
  colorDarkestViolet,
  colorDarkViolet,
  colorMediumViolet,
  colorViolet,
  colorLavender,
  colorLighterLavender,
  colorLightestLavender,
  colorOrange,
  colorOrangeLight,
  colorWhite,
  colorGrayCool,
  colorGrayCoolLighter,
  colorGrayCoolLightest,
  colorGrayWarm,
  colorGrayMedium,
  colorNavyDark,
  colorCharcoal,
  colorBlackWarm,
  colorYellow,
  colorYellowLight,
  colorGreen,
  colorGreenLight,
  colorGreenLightest,
  colorRed,
  colorRedDark,
  colorRedDarkest,
  colorRedLight,
  colorBlue,
  colorBlueLight,
} = colors;
